import { Props as StickerProps } from 'app/components/Sticker'
import { StickerDataQuery } from 'graphql-types'

export const getStickerProps = (
  data: StickerDataQuery,
  languageCode: string,
  languagePrefix: string,
): StickerProps | undefined => {
  const sticker = data.cms.sticker

  if (!sticker) {
    return undefined
  }

  const isActive = sticker.status === 'enabled'
  const imageObj = sticker.image?.file?.childImageSharp?.gatsbyImageData
  const image = {
    alt: sticker.image?.title || undefined,
    src: imageObj.images.fallback.src || undefined,
    srcSet: imageObj.images.fallback.srcSet || undefined,
    width: imageObj.width || undefined,
    height: imageObj.height || undefined,
  }

  const translation = sticker.translations?.find(
    (t: any) => t?.languages_code?.code === languageCode,
  )

  const button = translation?.button
  const buttonURL = languagePrefix
    ? `/${languagePrefix}/${button?.url}`
    : `/${button?.url}`
  const cta = button?.label
    ? [
        {
          file: button?.file?.file?.publicURL || undefined,
          label: button?.label,
          target: button?.target || undefined,
          URL: buttonURL || undefined,
        },
      ]
    : undefined
  const description = translation?.description || undefined
  const label = translation?.label || undefined
  const title = translation?.title || undefined

  return isActive
    ? {
        cta,
        description,
        label,
        image,
        title,
      }
    : undefined
}
