import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import SwiperCore, { Autoplay, Lazy, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Experience, Props as ExperienceProps } from './Experience'

SwiperCore.use([Autoplay, Lazy, Pagination])

export interface Props {
  description?: string
  experiences?: ExperienceProps[]
  label?: string
  title?: string
}

export const ExperiencesGrid = memo(function ExperiencesGrid({
  description,
  experiences,
  label,
  title,
}: Props) {
  return (
    <Container>
      {label ? (
        <FadeInOutAnimation>
          <Label>{label}</Label>
        </FadeInOutAnimation>
      ) : null}
      {title ? (
        <FadeInOutAnimation>
          <Title>{title}</Title>
        </FadeInOutAnimation>
      ) : null}
      {description ? (
        <FadeInOutAnimation>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInOutAnimation>
      ) : null}
      <FadeInOutAnimation>
        <Grid row wrap>
          {experiences
            ? experiences
                .slice(0, 3)
                .map((item, index) => <Experience key={index} {...item} />)
            : null}
        </Grid>
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.75rem 0;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 7.5rem 0;
  }

  @media (max-width: 767px) {
    margin: 5.25rem 0;
  }
`

const Label = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 0.9375rem;
  opacity: 0.4;
  margin-bottom: 0.375rem;
  text-transform: uppercase;
`

const Title = styled.h3`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
  &:before,
  &:after {
    content: '';
    flex: 1 1;
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
    height: 2px;
  }
  &:before {
    margin-right: 1.5rem;
  }
  &:after {
    margin-left: 1.5rem;
  }
`

const Description = styled.div`
  max-width: 46.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin: auto;
  padding: 0 1.5rem;
`

const Grid = styled(FlexBox)`
  max-width: 73.5rem;
  margin: 3.75rem auto 0;
  padding: 0 1.5rem;
`
