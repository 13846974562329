import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import React, { memo, useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  label?: string
  image?: { [key: string]: any }
  title?: string
}

export const Sticker = memo(function Sticker({
  cta,
  description,
  label,
  image,
  title,
}: Props) {
  const [modalStickerStatus, setModalStickerStatus] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 1023) {
      enableBodyScroll(document.body)
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <Container>
      {label ? (
        <Label
          onClick={() => {
            if (!modalStickerStatus) {
              setModalStickerStatus(true)
              if (window.innerWidth > 1023) {
                disableBodyScroll(document.body)
              } else {
                document.body.style.overflow = 'hidden'
              }
            } else {
              setModalStickerStatus(false)
              if (window.innerWidth > 1023) {
                enableBodyScroll(document.body)
              } else {
                document.body.style.overflow = 'auto'
              }
            }
          }}
        >
          <Inner>{label}</Inner>
          <Icon />
        </Label>
      ) : null}
      <Modal className={modalStickerStatus ? 'open' : ''} dial={5}>
        <Wrapper row stretch>
          {image ? (
            <ImageWrap>
              <LazyLoad once>
                <Image {...image} />
              </LazyLoad>
            </ImageWrap>
          ) : null}
          <TextWrap className={!image ? 'full-width' : undefined}>
            <ModalClose
              onClick={() => {
                setModalStickerStatus(false)
                if (window.innerWidth > 1023) {
                  enableBodyScroll(document.body)
                } else {
                  document.body.style.overflow = 'auto'
                }
              }}
            />
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta
              ? cta.map((item, index) => (
                  <CTA className="sticker-cta" key={index} {...item} />
                ))
              : null}
          </TextWrap>
        </Wrapper>
      </Modal>
    </Container>
  )
})

const Container = styled.aside`
  padding: 0 1.5rem;
`

const Label = styled.div`
  width: 6.875rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  line-height: 26px;
  padding: 1.25rem 1.5rem 2rem 1.1875rem;
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 300;
  transform: translateY(-50%);
  transition: 0.3s ease-out;
  &:hover {
    width: 8.375rem;
    > div {
      opacity: 1;
    }
  }

  @media (max-width: 1023px) {
    display: flex;
    justify-content: space-between;
    width: auto;
    padding: 1rem 1.5rem;
    top: auto;
    bottom: 74px;
    right: 1.5rem;
    left: 1.5rem;
    transform: none;
    &:hover {
      width: auto;
    }
  }
`

const Inner = styled.span`
  display: block;
  max-width: 60px;
`

const Icon = styled.div`
  width: 14px;
  height: 14px;
  margin-top: 0.75rem;
  opacity: 0.3;
  position: relative;
  transition: 0.3s ease-out;
  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    top: 0;
    left: 0;
    transform: rotate(90deg);
  }
`

const Modal = styled(FlexBox)`
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

const ModalClose = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    width: 20px;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.neutralDark3};
    position: absolute;
    top: 1.125rem;
    left: 0.625rem;
    transition: 0.3s ease-out;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 73.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: auto;
`

const ImageWrap = styled.div`
  width: 50%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.05);
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const TextWrap = styled.div`
  width: 50%;
  padding: 3.75rem 3.75rem 8.375rem;
  position: relative;
  &.full-width {
    width: 100%;
    text-align: center;
    .sticker-cta {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 3rem 1.5rem 5.25rem;
    .sticker-cta {
      left: 1.5rem;
      transform: none;
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`

const CTA = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 3.75rem;
`
