import { Sticker as StickerComponent } from 'app/components/Sticker'
import { graphql, StaticQuery } from 'gatsby'
import React, { memo } from 'react'

import { getStickerProps } from './getStickerProps'

export interface Props {
  languageCode: string
  languagePrefix: string
}

export const Sticker = memo(function Sticker({
  languageCode,
  languagePrefix,
}: Props) {
  return (
    <StaticQuery
      query={graphql`
        query StickerData {
          cms {
            sticker {
              status
              image {
                id
                title
                file {
                  id
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 600)
                  }
                }
              }
              translations {
                languages_code {
                  code
                  prefix
                }
                label
                title
                description
                button {
                  file {
                    id
                    filename_download
                    file {
                      publicURL
                    }
                  }
                  label
                  url
                  target
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const props = getStickerProps(data, languageCode, languagePrefix)

        return props ? <StickerComponent {...props} /> : null
      }}
    />
  )
})
