import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface Props {
  category?: string
  image?: string
  languageCode: string
  title: string
  URL?: string
}

export const Experience = memo(function Experience({
  category,
  image,
  languageCode,
  title,
  URL,
}: Props) {
  return (
    <Container to={URL ? URL : ''}>
      {image ? (
        <Image
          className="experience-image"
          style={{ backgroundImage: `url(${image})` }}
        />
      ) : null}
      <TextWrap className="experience-text-wrap" dial={5}>
        <Info>
          {category ? <Category>{category}</Category> : null}
          <Title className="experience-title">{title}</Title>
        </Info>
        {URL ? (
          <CTA
            className="experience-cta"
            label={useVocabularyData('find-out', languageCode)}
            noActions
          />
        ) : null}
      </TextWrap>
    </Container>
  )
})

const Container = styled(Link)`
  width: calc(33.333% - 2px);
  overflow: hidden;
  margin: 0 1px;
  padding-bottom: 42%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:hover {
    .experience-image {
      transform: scale(1);
    }
    .experience-text-wrap {
      &:before {
        opacity: 1;
      }
      > div {
        &:before {
          height: 2.25rem;
        }
      }
    }
    .experience-cta {
      bottom: 0;
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 1.5rem 0 0;
    padding-bottom: 66%;
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.1);
  transition: 0.3s ease-out;
`

const TextWrap = styled(FlexBox)`
  width: calc(100% - 5rem);
  height: calc(100% - 5rem);
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding: 3.75rem 1.5rem;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;
  z-index: 2;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark4};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.03);
    transition: 0.3s ease-out;
  }

  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    top: 0;
    left: 0;
  }
`

const Info = styled.div`
  position: relative;
  &:before {
    content: '';
    width: 2px;
    height: 0;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    position: absolute;
    top: -3.25rem;
    left: 50%;
    transition: 0.3s ease-out;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 2.25rem;
`

const Category = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 1px;
  line-height: 0.875rem;
  opacity: 0.4;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

const CTA = styled(Button)`
  position: absolute;
  bottom: -1rem;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  transition: 0.3s ease-out;

  @media (max-width: 1023px) {
    opacity: 1;
    bottom: 0;
  }
`
