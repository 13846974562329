import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  description?: string
  source?: string
  title?: string
  user?: string
}

export const Review = memo(function Review({
  description,
  source,
  title,
  user,
}: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      <ReviewInfo dial={5} row>
        {user ? <User>{user}</User> : null}
        {user && source ? ',\xa0' : null}
        {source ? <Source>{source}</Source> : null}
      </ReviewInfo>
    </Container>
  )
})

const Container = styled.section``

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
`

const Description = styled.div`
  display: block;
  display: -webkit-box;
  max-height: 4.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  line-height: 1.5rem;
  opacity: 0.5;
  overflow: hidden;
  margin-bottom: 2.25rem;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  p {
    margin: 0;
  }
`

const ReviewInfo = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.6875rem;
  letter-spacing: 1px;
  line-height: 0.875rem;
  text-transform: uppercase;
`

const User = styled.div``

const Source = styled.div`
  font-weight: 600;
`
