import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInOutAnimation } from 'app/components/Common/FadeInOutAnimation'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'

import { Props as ServicesProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  image?: string
  services?: ServicesProps[]
  title?: string
}

export const IconsServices = memo(function IconsServices({
  cta,
  description,
  image,
  services,
  title,
}: Props) {
  return (
    <Container row space="between">
      <FadeInOutAnimation>
        <>
          {image ? (
            <LazyLoad once>
              <Image style={{ backgroundImage: `url(${image})` }} />
            </LazyLoad>
          ) : null}
          <TextWrap>
            {title ? <Title>{title}</Title> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {cta
              ? cta.map((item, index) => <Button key={index} {...item} />)
              : null}
          </TextWrap>
        </>
      </FadeInOutAnimation>
      <FadeInOutAnimation>
        {services ? (
          <ServicesList row wrap>
            {services.map((item, index) => {
              return <Service key={index} {...item} />
            })}
          </ServicesList>
        ) : null}
      </FadeInOutAnimation>
    </Container>
  )
})

const Container = styled(FlexBox)`
  max-width: 76.5rem;
  margin: 9.75rem auto;
  padding: 0 1.5rem;
  > div {
    width: calc(50% - 1px);
    position: relative;
    &:last-of-type {
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
      margin-top: 3.75rem;
    }
  }

  @media (max-width: 1199px) {
    margin: 5.25rem auto;
  }

  @media (max-width: 767px) {
    display: block;
    padding: 0;
    > div {
      width: 100%;
      padding: 1.5rem;
      &:last-of-type {
        margin-top: 0;
      }
    }
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  top: 0;
  left: 0;
`

const TextWrap = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 3.75rem;
  overflow: hidden;
  padding: 6.125rem 5.25rem 0;
  position: relative;
  text-align: center;

  @media (max-width: 1199px) {
    margin: 3rem;
    padding: 3.75rem 3rem 0;
  }

  @media (max-width: 1023px) {
    margin: 1.5rem;
  }

  @media (max-width: 767px) {
    margin: 0;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.625rem;
  line-height: 3.375rem;
  margin-bottom: 1.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  margin-bottom: 6.125rem;

  @media (max-width: 1199px) {
    margin-bottom: 3.75rem;
  }
`

const ServicesList = styled(FlexBox)`
  padding: 3.8125rem 7.375rem 7.375rem;

  @media (max-width: 1199px) {
    padding: 1.5rem 5.25rem 5.25rem;
  }

  @media (max-width: 1023px) {
    padding: 0 1.5rem 3rem;
  }

  @media (max-width: 767px) {
    padding-bottom: 2.25rem;
  }
`
